<template>
  <a-card :bordered="false">

    <div class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="8">
          <a-col :md="4" :sm="24">
            <a-form-item label="开始时间">
              <a-input v-model="queryParam.startTime" placeholder=""/>
            </a-form-item>
          </a-col>
          <a-col :md="4" :sm="24">
            <a-form-item label="结束时间">
              <a-input v-model="queryParam.endTime" placeholder=""/>
            </a-form-item>
          </a-col>
          <a-col :md="4" :sm="24">
            <a-form-item label="已读状态">
              <a-select v-model="queryParam.readed" placeholder="请选择" default-value="0">
                <a-select-option value="0">全部</a-select-option>
                <a-select-option value="1">未读</a-select-option>
                <a-select-option value="2">已读</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :md="!advanced && 8 || 24" :sm="24">
            <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
              <a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
              <a-button style="margin-left: 8px" @click="() => queryParam = {}">重置</a-button>
            </span>
          </a-col>
        </a-row>
      </a-form>
    </div>

    <div class="table-operator">
<!--      <a-button type="primary" icon="plus" @click="handleAdd()" >新建</a-button>-->
    </div>

    <s-table
      ref="table"
      size="default"
      :rowKey="(record) => record.id"
      :columns="columns"
      :pagination="pagination"
      :data="loadData"
      :pageSize="50"
      :rowSelection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
      showPagination="auto"
      :scroll="{ x: 1500,y:scrollHeight}"
    >
      <!--      <span slot="serial" slot-scope="text, record, index">-->
      <!--        {{ index + 1 }}-->
      <!--      </span>-->
      <span slot="action" slot-scope="text, record" >
          <template>
            <a @click="handleEdit(record)" v-show="record.readed==0">已读</a>
          </template>
        </span>

    </s-table>



  </a-card>
</template>

<script>
import moment from 'moment'
import { STable } from '@/components'
import { ExclamationCircleOutlined } from '@ant-design/icons-vue';
import { createVNode, defineComponent } from 'vue';
import { Modal } from 'ant-design-vue';
import {
  deleteRole,
  findPageMessage,
  updateMessage
} from '@/api/manage'

export default {
  name: 'TableList',
  components: {
    STable,
  },
  data () {
    return {
      mdl: {},
      openKeys: ['1'],
      // 高级搜索 展开/关闭
      advanced: false,
      pagination: {pageSizeOptions: ['50', '100','200','500']},
      treeData:[],
      // 查询参数
      queryParam: {},
      // 表头
      columns: [
        {
          title: '标题',
          width: '150px',
          key:'title',
          dataIndex:'title'
        },
        {
          title: '内容',
          width: '250px',
          key:'content',
          dataIndex:'content'
        },
        {
          title: '来源',
          width: '80px',
          key:'source',
          dataIndex:'source',
          customRender: (text) => {
            let ret = ""
            if(text==1){
              ret = '服务商平台'
            }else if(text==2){
              ret = '中心平台'
            }else{
              ret = '三方平台'
            }
            return ret
          }
        },
        {
          title: '备注',
          width: '80px',
          key:'remark',
          dataIndex:'remark'
        },
        {
          title: '已读',
          width: '50px',
          key:'readed',
          dataIndex:'readed',
          customRender: (text) => {
            let ret = ""
            if(text==1){
              ret = '已读'
            }else{
              ret = '未读'
            }
            return ret
          }
        },
        {
          title: '状态',
          width: '50px',
          key:'status',
          dataIndex:'status',
          customRender: (text) => {
            let ret = ""
            if(text==1){
              ret = '正常'
            }else{
              ret = '作废'
            }
            return ret
          }
        },
        {
          title: '操作',
          dataIndex: 'action',
          width: '80px',
          scopedSlots: { customRender: 'action' }
        },
      ],
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        console.log('loadData.parameter', parameter)
        let param = {};
        parameter.type=20
        param = Object.assign(parameter, this.queryParam)
        return findPageMessage(param)
          .then(res => {
            return res.result
          })
      },
      selectedRowKeys: [],
      selectedRows: [],
      options: {
        alert: { show: true, clear: () => { this.selectedRowKeys = [] } },
        rowSelection: {
          selectedRowKeys: this.selectedRowKeys,
          onChange: this.onSelectChange
        }
      },
      optionAlertShow: false,
      confirmLoading:false,
      form: this.$form.createForm(this),
      tabObj:{},//当前对象，编辑是用到
      scrollHeight:0
    }
  },
  created () {
    this.tableOption()
    this.scrollHeight = document.body.scrollHeight-400;
  },
  filters: {
  },
  methods: {
    tableOption () {
      if (!this.optionAlertShow) {
        this.options = {
          alert: { show: true, clear: () => { this.selectedRowKeys = [] } },
          rowSelection: {
            selectedRowKeys: this.selectedRowKeys,
            onChange: this.onSelectChange
          }
        }
        this.optionAlertShow = true
      } else {
        this.options = {
          alert: false,
          rowSelection: null
        }
        this.optionAlertShow = false
      }
    },
    handleEdit (record) {
      let that = this
      Modal.confirm({
        title: '确认已读',
        // icon: createVNode(ExclamationCircleOutlined),
        content: '确认已读吗',
        onOk() {
          return new Promise((resolve, reject) => {
            // setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
            updateMessage({id:record.id,readed:1})
              .then(res => {
                that.$message.success("更新成功")
                that.$refs.table.refresh()
              })
            setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
          }).catch(() => console.log('Oops errors!'));
        },
        onCancel() {},
      });
    },
    handleDelRole (record) {
    },
    handleOk () {

    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    resetSearchForm () {
      this.queryParam = {
        date: moment(new Date())
      }
    },
    addRoleHandleCancel () {
    },
    handleAdd () {
    },
    handleSubmit(e){
      e.preventDefault()

    },
    addRoleCancel(){
    },
    iscandel(record){
    },
    bindpermHandleCancel(){
    },
    handleBind(e){
    }

  }
}
</script>
<style lang="less" scoped>
.table-operator{
  margin-bottom: 10px;
}
</style>